import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import routes from './router/routes';
import AsyncComponent from './Loading/AsyncComponent';
import SEO from './seo';
import * as variableLocalContent from "./router/variablePath.json";

let variableContent;
switch (parseInt(process.env.REACT_APP_PROD_LOCATION)) {
  case 1:
    variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[0]];
    break;
  case 2:
    variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[1]];
    break;
  case 3:
      variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[2]];
      break;
  default:
    variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[0]];
    break;
}
const Footer = AsyncComponent(() => import('./Footer'));
const NavBar = AsyncComponent(() => import('./NavBar'));

class Main extends Component {

  render() {
    /* Adjusts CSS of navbar on certain routes */
    const conditionalNavBar = history => {
      // if {
      //   history.location.pathname === ??? ||
      // } {
      //   return <NavBar navVariant="navSolid" location={history.location} />;
      // }
      if (history.location.pathname === '/feedback' || history.location.pathname === 'feedback') {
        return <NavBar navVariant="navHide" location={history.location} local={variableContent}/>;
      } else {
        return <NavBar navVariant="navSolid" location={history.location} local={variableContent}/>;
      }
    }

    return (
      <div className="App">
        <header className="App-header">
          <SEO meta={variableContent.meta}/>
          <Route
            render={history => (
              <div>
                {conditionalNavBar(history)}
                {routes}
                <Footer local={variableContent}/>
              </div>
            )}
          />
        </header>
      </div>
    );
  }
}

export default Main;
