import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AsyncComponent from '../Loading/AsyncComponent';
import * as variableLocalContent from './variablePath.json';

// global location variable defined here to ensure it is well defined throughout the multiple pages in the app.
let variableContent;
switch (parseInt(process.env.REACT_APP_PROD_LOCATION)) {
  case 1:
    variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[0]];
    global.locationContext = {
      mainName: variableContent.longName,
      abrv: variableContent.shortName,
      turl: variableContent.topURL,
      mediumName: variableContent.mediumName,
    };
    break;
  case 2:
    variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[1]];
    global.locationContext = {
      mainName: variableContent.longName,
      abrv: variableContent.shortName,
      turl: variableContent.topURL,
      mediumName: variableContent.mediumName,
    };
    break;
  case 3:
    variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[2]];
    global.locationContext = {
      mainName: variableContent.longName,
      abrv: variableContent.shortName,
      turl: variableContent.topURL,
      mediumName: variableContent.mediumName,
    };
    break;
  default:
    variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[0]];
    global.locationContext = {
      mainName: variableContent.longName,
      abrv: variableContent.shortName,
      turl: variableContent.topURL,
      mediumName: variableContent.mediumName,
    };
    break;
  // case 4:
}

// console.log(variableContent.covidVariants[0].url);
// Components
const HomePage = AsyncComponent(() => import('../HomePage/HomePage'));
const Form = AsyncComponent(() => import('../Form/Form'));
const FormComplete = AsyncComponent(() => import('../Form/FormComplete'));
const AdminForm = AsyncComponent(() => import('../Form/AdminForm'));
const AdminFormComplete = AsyncComponent(() => import('../Form/AdminFormComplete'));
const Feedback = AsyncComponent(() => import('../Feedback/Feedback'));
const Team = AsyncComponent(() => import('../Team/Team'));
const Covid = AsyncComponent(() => import(`../Covid/${variableContent.covidVariants[0].file}`));
const CovidOne = AsyncComponent(() => import(`../Covid/${variableContent.covidVariants[1].file}`));
const CovidTwo = AsyncComponent(() => import(`../Covid/${variableContent.covidVariants[2].file}`));
const CovidThree = AsyncComponent(() =>
  import(`../Covid/${variableContent.covidVariants[3].file}`),
);
// const CovidFreeTesting = AsyncComponent(() => import('../Covid/CovidFreeTesting'));
const AboutUs = AsyncComponent(() => import('../AboutUs/AboutUs'));
const Careers = AsyncComponent(() => import('../Careers/Careers'));
const AccreditationsPage = AsyncComponent(() => import('../AboutUs/AccreditationsPage'));
const FAQ = AsyncComponent(() => import('../AboutUs/FAQ'));
const EmergencyServices = AsyncComponent(() => import('../EmergencyServices/EmergencyServices'));
const Laboratory = AsyncComponent(() => import('../EmergencyServices/Laboratory'));
const PediatricCare = AsyncComponent(() => import('../EmergencyServices/PediatricCare'));
const Pharmacy = AsyncComponent(() => import('../EmergencyServices/Pharmacy'));
const Radiology = AsyncComponent(() => import('../EmergencyServices/Radiology'));
const Rooms = AsyncComponent(() => import('../EmergencyServices/Rooms'));
const UrgentCare = AsyncComponent(() => import('../Billing/UrgentCare'));
const BillingProcess = AsyncComponent(() => import('../Billing/BillingProcess'));
const Insurance = AsyncComponent(() => import('../Billing/Insurance'));
const PayMyBill = AsyncComponent(() => import('../Billing/PayMyBill'));
const BillingFAQ = AsyncComponent(() => import('../Billing/BillingFAQ'));
const DeptOfSHS = AsyncComponent(() => import('../Billing/DeptOfSHS'));
const EntranceVideo = AsyncComponent(() => import('../EntranceAndExitVideos/EntranceVideo'));
const ExitVideo = AsyncComponent(() => import('../EntranceAndExitVideos/ExitVideo'));

export default (
  <Switch>
    <Route
      path="/"
      exact
      render={props => (
        <HomePage
          variant="homepage"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/flu"
      exact
      render={props => (
        <HomePage
          variant="flu"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/hospital"
      exact
      render={props => (
        <HomePage
          variant="hospital"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/urgentcarevser"
      exact
      render={props => (
        <HomePage
          variant="urgentcarevser"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/pediatric"
      exact
      render={props => (
        <HomePage
          variant="pediatric"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
      <Route
      path="/round-rock"
      exact
      render={props => (
        <HomePage
          variant="round-rock"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path={`/${variableContent.covidVariants[0].url}`}
      exact
      render={props => <Covid context={variableContent} location={props.location} />}
    />
    <Route
      path={`/${variableContent.covidVariants[1].url}`}
      exact
      render={props => <CovidOne context={variableContent} location={props.location} />}
    />
    <Route
      path={`/${variableContent.covidVariants[2].url}`}
      exact
      render={props => <CovidTwo context={variableContent} location={props.location} />}
    />
    <Route
      path={`/${variableContent.covidVariants[3].url}`}
      exact
      render={props => <CovidThree context={variableContent} location={props.location} />}
    />
    <Route
      path="/pediatriccare"
      exact
      render={props => (
        <PediatricCare
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route path="/form" exact component={Form} />
    <Route path="/formcomplete" exact component={FormComplete} />
    <Route path="/adminform" exact component={AdminForm} />
    <Route path="/adminformcomplete" exact component={AdminFormComplete} />
    <Route path="/feedback" exact component={Feedback} />
    <Route path="/team" exact component={Team} />
    <Route path="/aboutus" exact component={AboutUs} />
    <Route path="/careers" exact component={Careers} />
    <Route path="/accreditations" exact component={AccreditationsPage} />
    <Route path="/faq" exact component={FAQ} />
    <Route path="/emergencyservices" exact component={EmergencyServices} />
    <Route path="/laboratory" exact component={Laboratory} />
    <Route path="/pharmacy" exact component={Pharmacy} />
    <Route path="/radiology" exact component={Radiology} />
    <Route path="/rooms" exact component={Rooms} />
    <Route path="/urgentcare" exact component={UrgentCare} />
    <Route path="/billingprocess" exact component={BillingProcess} />
    <Route path="/insurance" exact component={Insurance} />
    <Route path="/paymybill" exact component={PayMyBill} />
    <Route path="/billingfaq" exact component={BillingFAQ} />
    <Route path="/deptofshs" exact component={DeptOfSHS} />
    <Route path="/video" exact component={EntranceVideo} />
    <Route path="/exitvideo" exact component={ExitVideo} />
    <Route
      path="/locations/mueller/"
      exact
      render={props => (
        <HomePage
          variant="homepage"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/locations/far-west/"
      exact
      render={props => (
        <HomePage
          variant="homepage"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/locations/anderson-mill/"
      exact
      render={props => (
        <HomePage
          variant="homepage"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/locations/south-lamar-2"
      exact
      render={props => (
        <HomePage
          variant="homepage"
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />

  </Switch>
);
