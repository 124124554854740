import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => (
  <div style={{ margin: 'auto', width: '150px', height: '100px', padding: '100px 0 200px 0' }}>
    <ReactLoading type="bars" color="gray" height="100px" width="150px" />
  </div>
);

export default Loading;
