import React from "react";
// import PropTypes from "prop-types";
import Helmet from "react-helmet";

function SEO(props) {
  // console.log(props)
  return(
    <Helmet
      title={`${props.meta.title} | 24 Hour Emergency Care | ER Open Now`}
      meta={[
        {
          name: `description`,
          content: props.meta.description,
        },
        // {
        //   name: `siteUrl`,
        //   content: "https://www.thecarsavior.com",
        // },
        // {
        //   property: `og:title`,
        //   content: data.site.siteMetadata.title,
        // },
        // {
        //   property: `og:description`,
        //   content: data.site.siteMetadata.description,
        // },
        // {
        //   property: `og:type`,
        //   content: `website`,
        // },
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   content: data.site.siteMetadata.author,
        // },
        // {
        //   name: `twitter:title`,
        //   content: data.site.siteMetadata.title,
        // },
        // {
        //   name: `twitter:description`,
        //   content: data.site.siteMetadata.description,
        // },
      ] }
    />
  );
  // }}
  // />
  // )
}

// SEO.defaultProps = {
//   lang: `en`,
//   meta: [],
//   keywords: [],
// }
//
// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.array,
//   keywords: PropTypes.arrayOf(PropTypes.string),
//   title: PropTypes.string.isRequired,
// }

export default SEO;

// const detailsQuery = graphql`
//   query DefaultSEOQuery {
//     site {
//       siteMetadata {
//         title
//         description
//         author
//       }
//     }
//   }
// `
